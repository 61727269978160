import React, { useContext, useEffect } from 'react';
import Navigation from '../components/navigation';
import SEO from '../components/seo';
import Footer from '../components/footer';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../context/GlobalContextProvider';

const About = () => {
    const { t, i18n } = useTranslation();
    const { language } = useContext(GlobalStateContext);
    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language]);

    // const team = [
    //     {
    //         name: "John Doe",
    //         role: "Manager",
    //         image: require('./../assets/images/pic01.jpg')
    //     },
    //     {
    //         name: "John Doe",
    //         role: "Manager",
    //         image: require('./../assets/images/pic01.jpg')
    //     },
    //     {
    //         name: "John Doe",
    //         role: "Manager",
    //         image: require('./../assets/images/pic01.jpg')
    //     },
    //     {
    //         name: "John Doe",
    //         role: "Manager",
    //         image: require('./../assets/images/pic01.jpg')
    //     },
    // ]

    return (
        <div id="page-wrapper">
            <SEO title="Nosotros" />
            <Navigation />
            <ToastContainer/>

            <section id="wrapper">
                <header>
                    <div className="inner text-light">
                        <h2>{t('menu.about')}</h2>
                        <p>{t('about.slogan')}</p>
                    </div>
                </header>

                <div className="wrapper">
                    <div className="inner text-light">
                        <h3 className="major">{t('about.summary.title')}</h3>
                        <p>{t('about.summary.description1')}</p>
                        <p>{t('about.summary.description2')}</p>

                        <div className="row">
                            <div className="col-md-6">
                                <h4 className="major">{t('about.mission.title')}</h4>
                                <p>{t('about.mission.description')}</p>
                            </div>
                            <div className="col-md-6">
                                <h4 className="major">{t('about.vision.title')}</h4>
                                <p>{t('about.vision.description')}</p>
                            </div>
                            <div className="col-12">
                                <h4 className="major">{t('about.values.title')}</h4>
                                <ul>
                                    {t('about.values.list').split(',').map((value, index) => (
                                        <li key={index}>{value}</li>
                                    ))}
                                </ul>
                            </div>
                            {/* <div className="col-12">
                                <h4 className="major">Nuestro equipo</h4>
                                <p>Nos conformamos de un equipo multidisciplinario, especializado en las áreas de negocio que tú necesitas.</p>
                                <div className="row">
                                    {team.map((member, index) => (
                                        <div className="col-sm-6 col-md-4 col-lg-3 text-center" key={index}>
                                            <img src={member.image} alt={member.name} style={{width: "100%"}} />
                                            <h5 style={{fontWeight: "bold", marginTop: 15}}>{member.name}</h5>
                                            <h6>{member.role}</h6>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default About;